import React, { useEffect, useState } from 'react';
import '../styles/MapView.css';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Polyline,
  OverlayView,
  InfoWindowF,
} from '@react-google-maps/api';
import { useMediaQuery, useTheme } from '@mui/material';

const libraries = ["maps"]; 

const MapView = ({ data, clickLocation, setClickLocation, placeImages }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const containerStyle = {
    width: isMobile ? '100%' : '50%',
    height: isMobile ? '100%' : '100%',
    position: 'absolute',
    marginTop: isMobile ? '16%' : '0%',
  };
  const [mapCenter, setMapCenter] = useState();
  const locations = Array.isArray(data) ? data : data ? [data] : [];
  function createTravelPaths(locations) {
    const paths = [];
    for (let i = 0; i < locations.length - 1; i++) {
      const currentLocation = locations[i];
      const nextLocation = locations[i + 1];
      if (currentLocation.GeoPoints.length !== 0) {
        paths.push({
          description: `Line from ${currentLocation.Place} to ${nextLocation.Place}`,
          coordinates: [
            {
              lat: parseFloat(currentLocation.GeoPoints.split(',')[0]?.trim()),
              lng: parseFloat(currentLocation.GeoPoints.split(',')[1]?.trim()),
            },
            {
              lat: parseFloat(nextLocation.GeoPoints.split(',')[0]?.trim()),
              lng: parseFloat(nextLocation.GeoPoints.split(',')[1]?.trim()),
            },
          ],
        });
      }
    }
    return paths;
  }

  const travelPaths = createTravelPaths(locations);

  function findGeographicCenter(locationsCenter) {
    let sumLat = 0;
    let sumLng = 0;
    const numLocations = locations.length;

    for (let i = 0; i < numLocations; i++) {
      sumLat += parseFloat(locationsCenter[i]?.GeoPoints.split(',')[0]?.trim());
      sumLng += parseFloat(locationsCenter[i]?.GeoPoints.split(',')[1]?.trim());
    }

    return {
      lat: sumLat / numLocations,
      lng: sumLng / numLocations,
    };
  }
  const center = findGeographicCenter(locations);
  // {
  //   lat: parseFloat(locations[0]?.GeoPoints.split(',')[0]?.trim()),
  //   lng: parseFloat(locations[0]?.GeoPoints.split(',')[1]?.trim()),
  // };
  const [map, setMap] = React.useState(null);
  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(4);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const CustomMarker = ({ text, lat, lng }) => {
    const getPixelPositionOffset = (width, height) => ({
      x: -(width + 10),
      y: -(height + 55),
    });

    return (
      <>
        <OverlayView
          position={{ lat, lng }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <div
            style={{
              background: 'white',
              border: '1px solid black',
              padding:
                text.length > 2 ? '1px 25px 1px 3px' : '1px 17px 1px 3px',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          >
            {text}
          </div>
        </OverlayView>
        <Marker
          position={{
            lat,
            lng,
          }}
        ></Marker>
      </>
    );
  };
  useEffect(() => {
    if (clickLocation) setMapCenter(clickLocation);
  }, [clickLocation]);
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={4}
      center={mapCenter || center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        zoomControl: false, // Disable zoom controls
        mapTypeControl: false, // Disable map type control
        streetViewControl: false, // Optionally disable the street view control
        rotateControl: false, // Optionally disable the rotate control
        scaleControl: false, // Optionally disable the scale control
        fullscreenControl: false,
        keyboardShortcuts: false,
        styles: [
          { featureType: 'poi.attraction', stylers: [{ visibility: 'off' }] },
        ],
      }}
    >
      {locations?.map((location, index) => {
        if (location?.GeoPoints?.length !== 0) {
          return (
            <CustomMarker
              key={index}
              text={location.Day}
              lat={parseFloat(location.GeoPoints.split(',')[0].trim())}
              lng={parseFloat(location.GeoPoints.split(',')[1].trim())}
            />
          );
        } else {
          return null;
        }
      })}
      {clickLocation && (
        <InfoWindowF
          position={clickLocation}
          onCloseClick={() => setClickLocation(null)}
          options={{}}
        >
          <div
            style={{
              position: 'relative', // Needed for absolute positioning of the close button
              display: 'flex',
              flexDirection: 'column',
              background: 'white',
              padding: '10px', // Adjusted to provide padding around the content
              borderRadius: '8px',
              maxWidth: '300px',
              height: ' 300px !important',
              maxHeight: '300px !important',
              // maxHeight:'300px'
            }}
          >
            <button
              onClick={() => setClickLocation(null)}
              style={{
                position: 'absolute',
                top: '0px',
                right: '2px', // Distance from the right of the container
                padding: '1px 8px',
                borderRadius: '4px',
                border: 'none',
                cursor: 'pointer',
                background: 'white',
                color: 'black',
                fontSize: '20px',
              }}
            >
              ×
            </button>

            <div
              style={{
                display: 'flex',
                overflowX: 'auto',
                marginTop: '20px', // Adjusted to give space under the close button
              }}
            >
              {placeImages?.result?.photos?.map((photo, index) => (
                <img
                  key={index}
                  src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${photo.photo_reference}&key=${process.env.REACT_APP_MAP_API_KEY}`}
                  alt="Place"
                  style={{
                    height: isMobile ? '70px' : '100px',
                    marginRight: '2px',
                    width: 'auto',
                    borderRadius: '5px',
                  }}
                />
              ))}
            </div>

            <div style={{ textAlign: 'center' }}>
              <h4 style={{ margin: isMobile ? '0 0 1px 0' : '0 0 10px 0' }}>
                {placeImages?.result?.name}
              </h4>
              {/* {console.log(mapLocationName)} */}
              <a
                href={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeImages?.result?.place_id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#007bff',
                  textDecoration: 'none',
                  margin: '5px 0',
                }}
              >
                View on Google Maps
              </a>
            </div>
          </div>
        </InfoWindowF>
      )}
      {travelPaths?.map((path, index) => (
        <Polyline
          key={index}
          path={path.coordinates}
          options={{
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            radius: 30000,
            zIndex: 1,
          }}
        />
      ))}{' '}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default MapView;
