import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Grid, Badge } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import the Close icon
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Slider from 'react-slick';

const MapModal = ({
  open,
  onClose,
  locations,
  selectedCountries,
  handleCountryClick,
  newItemsList,
  handleRemoveLocation,
  handleCardClick,
  handleRemoveCard,
  addCard,
  sliderRef,
  cards,
  handlePinClick,
  selectedIndex,
}) => {
  const [center, setCenter] = useState({
    lat: locations[0]?.lat || 0, // Center the map on the first location
    lng: locations[0]?.lng || 0,
  });
  const [map, setMap] = useState(null);
  const [countryBounds, setCountryBounds] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });
  useEffect(() => {
    const fetchCountryBounds = async () => {
      try {
        const response = await fetch(`https://oreinjoai-v2.trixlyai.com/api/geocode?country=${locations?.country}`);
        const data = await response.json();
        const bounds = data.results[0]?.geometry?.bounds;
        const viewport = data.results[0]?.geometry?.viewport;
        if (bounds || viewport) {
          const latLngBounds = new window.google.maps.LatLngBounds(
            viewport?.southwest,
            viewport?.northeast
          );
          setCountryBounds(latLngBounds);
        }
      } catch (error) {
        console.error('Error fetching country bounds:', error);
      }
    };
  
    fetchCountryBounds();
  }, [locations?.country]);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  useEffect(() => {
    if (
      map &&
      countryBounds &&
      (selectedCountries.length == 0 || selectedCountries[0] === 'anywhere')
    ) {
      map.fitBounds(countryBounds, {
        padding: { top: 100, bottom: 10, left: 10, right: 10 },
      });
    }
  }, [map, countryBounds]);
  useEffect(() => {
    if (
      map &&
      selectedCountries.length > 0 &&
      selectedCountries[0] !== 'anywhere'
    ) {
      let lastLocation;
      Object.entries(locations?.places).forEach(([region, countryList]) => {
        countryList.forEach((location) => {
          if (
            location.placename ===
            selectedCountries[selectedCountries.length - 1]
          ) {
            lastLocation = location;
          }
        });
      });

      map.panTo({
        lat: lastLocation.latitude,
        lng: lastLocation.longitude,
      });
      setTimeout(() => {
        map.setZoom(7);
      }, 500);
    }
  }, [selectedCountries, map]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [indexChange, setIndexChange] = useState(false);
  useEffect(() => {
    if (
      map &&
      selectedCountries.length > 0 &&
      currentIndex !== null &&
      indexChange && currentIndex < cards.length
    ) {
      let lastLocation = cards[currentIndex].location;
      handlePinClick(lastLocation.placename);
      map.panTo(
        { lat: lastLocation.latitude, lng: lastLocation.longitude },
        {
          duration: 1000,
        }
      );
      setIndexChange(false);
    }
  }, [currentIndex, map]);
  const settings = {
    dots: false,
    infinite: cards?.length + newItemsList?.length > 1 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'center',
    centerMode: true,
    centerPadding: '0px',
    afterChange: (currentIndex) => {
      setCurrentIndex(currentIndex);
      setIndexChange(true);
    },
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  let iconMarkerSelect = new window.google.maps.MarkerImage(
    'https://res.cloudinary.com/dyidgwoxx/image/upload/v1732062152/d87chk4st6csbi5wkdtj.png',
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(26, 32)
  );
  let iconMarker = new window.google.maps.MarkerImage(
    'https://res.cloudinary.com/dyidgwoxx/image/upload/v1732062152/ehyewky1b7yenpdiwwdr.png',
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(22, 26)
  );
  let iconMarkerBlack = new window.google.maps.MarkerImage(
    'https://res.cloudinary.com/dyidgwoxx/image/upload/v1732062704/wbzrrxkpuleoecglhwk6.png',
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(26, 32)
  );
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '55%',
          bgcolor: 'transparent',
          boxShadow: 24,
          overflow: 'hidden',
          borderRadius: 0,
        }}
      >
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          onLoad={(mapInstance) => setMap(mapInstance)}
          zoom={12}
          // onUnmount={onUnmount}
          options={{
            disableDefaultUI: true,
            streetViewControl: false,
            styles: [
              {
                featureType: 'poi.attraction',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'road',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
              },
            ],
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: '0px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '85%',
              // bgcolor: "rgba(255, 255, 255, 0.9)",
              // borderRadius: '10px',
              // boxShadow: 3,
              // p: 2,
            }}
          >
            <Slider {...settings} ref={sliderRef}>
              {cards &&
                cards?.map((country, index) => (
                  <Box
                    key={index}
                    sx={{
                      pt: 1,
                      pb: 1,
                    }}
                  >
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      badgeContent={
                        <Button
                          sx={{
                            cursor: 'pointer',
                            color: 'black',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            handleCountryClick(country.location.placename);
                            handleRemoveCard(country.location.placename);
                          }}
                        >
                          <RemoveCircleIcon sx={{ fontSize: 20 }} />
                        </Button>
                      }
                    >
                      <Grid container xs={12} sm={12} md={12}>
                        <Grid
                          xs={10}
                          sm={10}
                          md={10}
                          pr={0.4}
                          style={{
                            display: 'flex',
                            alignItems: 'stretch',
                            height: '72px',
                          }}
                        >
                          <button
                            className={`country-button ${
                              selectedCountries.includes(
                                country.location.placename
                              )
                                ? 'selected'
                                : ''
                            }`}
                            style={{
                              border: 'none',
                              borderRadius: '5px 0 0 5px',
                              margin: 0,
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                            }}
                            onClick={() => {
                              handlePinClick(country.location.placename);
                            }}
                          >
                            <strong>{country.location.placename}:</strong>{' '}
                            {country.location.description}
                          </button>
                        </Grid>
                        <Grid
                          xs={2}
                          sm={2}
                          md={2}
                          style={{
                            display: 'flex',
                            alignItems: 'stretch',
                          }}
                        >
                          <img
                            style={{
                              width: '100%',
                              height: '100%',
                              borderRadius: '0 6px 6px 0',
                            }}
                            src={
                              country?.location?.images_url[0] ||
                              'https://res.cloudinary.com/dyidgwoxx/image/upload/v1732135489/dd60j5izvyns5w8cvwal.png'
                            }
                            alt={country.location.placename}
                          />
                        </Grid>
                      </Grid>
                    </Badge>
                  </Box>
                ))}

              {newItemsList &&
                newItemsList?.map((country, index) => (
                  <Box
                    key={index}
                    sx={{
                      pt: 1,
                      pb: 1,
                    }}
                  >
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      badgeContent={
                        <Button
                          sx={{
                            cursor: 'pointer',
                            color: 'black',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleRemoveLocation(country)}
                        >
                          <RemoveCircleIcon sx={{ fontSize: 20 }} />
                        </Button>
                      }
                    >
                      <Grid container xs={12} sm={12} md={12}>
                        <Grid
                          xs={10}
                          sm={10}
                          md={10}
                          pr={0.4}
                          style={{
                            display: 'flex',
                            alignItems: 'stretch',
                            height: '72px',
                          }}
                        >
                          <button
                            className={`country-button ${
                              selectedCountries.includes(country)
                                ? 'selected'
                                : ''
                            }`}
                            style={{
                              border: 'none',
                              borderRadius: '5px 0 0 5px',
                              margin: 0,
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <strong>{country}</strong>{' '}
                          </button>
                        </Grid>
                        <Grid
                          xs={2}
                          sm={2}
                          md={2}
                          style={{
                            display: 'flex',
                            alignItems: 'stretch',
                          }}
                        >
                          <img
                            style={{
                              width: '100%',
                              height: '100%',
                              borderRadius: '0 6px 6px 0',
                            }}
                            src={
                              'https://res.cloudinary.com/dyidgwoxx/image/upload/v1732135489/dd60j5izvyns5w8cvwal.png'
                            }
                            alt={country}
                          />
                        </Grid>
                      </Grid>
                    </Badge>
                  </Box>
                ))}
            </Slider>
          </Box>
          {isLoaded &&
            Object.entries(locations?.places || {}).map(
              ([region, countryList]) => {
                return countryList.map((location, index) => (
                  <MarkerF
                    key={`${region}-${index}`}
                    position={{
                      lat: location.latitude,
                      lng: location.longitude,
                    }}
                    icon={
                      selectedCountries.includes(location.placename) &&
                      cards.some(
                        (card) =>
                          card.name === location.placename && card.selected
                      )
                        ? iconMarkerBlack
                        : selectedCountries.includes(location.placename)
                        ? iconMarkerSelect
                        : iconMarker
                    }
                    onClick={() => {
                      if (selectedCountries.includes(location.placename)) {
                        handleCardClick(location.placename);
                      } else {
                        handleCountryClick(location.placename);
                        addCard(location.placename, location);
                      }
                    }}
                  />
                ));
              }
            )}
        </GoogleMap>
        <Button
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 3,
            right: 3,
            backgroundColor: 'transparent',
            color: 'black',
            minWidth: 0,
            padding: 0,
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          <CloseIcon sx={{ fontSize: 40 }} />
        </Button>
      </Box>
    </Modal>
  );
};

export default MapModal;
