import React, { useState } from 'react';
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  Box,
} from '@mui/material';
import TravelStyleCard from './TravelStyleCard';
import '../styles/TravelStyleSelection.css';
import ProgressIndicator from './ProgressIndicator';
import NextButton from './NextButton';
import { useNavigate, useLocation } from 'react-router-dom';

const travelStyles = [
  {
    name: 'Relaxed',
    icon: 'https://i.ibb.co/tMfjptX/Screenshot-from-2024-06-15-20-06-47-removebg-preview.png',
  },
  {
    name: 'Moderate',
    icon: 'https://i.ibb.co/dW2DZ1K/Screenshot-from-2024-06-15-20-06-55-removebg-preview.png',
  },
  {
    name: 'Blitz Trip',
    icon: 'https://i.ibb.co/G74F37r/Screenshot-from-2024-06-15-20-07-00-removebg-preview.png',
  },
];

const TravelStyleSelection = () => {
  const [selectedStyle, setSelectedStyle] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const country = queryParams.get('country');
  const land = queryParams.get('land');
  const getaway = queryParams.get('getaway');
  const city = queryParams.get('city');
  const startLocation = queryParams.get('startLocation');
  const endLocation = queryParams.get('endLocation');
  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');
  const transit = queryParams.get('transit');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSelection = (name) => {
    setSelectedStyle(name);
  };
  const navigate = useNavigate();
  const [extraStyle, setExtraStyle] = useState('');
  const handleNext = () => {
    navigate(
      `/trip-planner?country=${country}&land=${land}&city=${city}&getaway=${getaway}&startLocation=${startLocation}&endLocation=${endLocation}&startDate=${startDate}&endDate=${endDate}&transit=${transit}&style=${
        selectedStyle + ',' + extraStyle
      }`
    );
  };
  return (
    <div className="selection-screen">
      <div className="selection-content">
        <div className="selection-section">
          <div className="travel-style-selection-screen">
            <div className="selection-area">
              <Typography
                variant="h5"
                gutterBottom
                pb={2}
                pt={isMobile ? 8 : 0}
                style={{ fontWeight: 'bold', textAlign: 'start' }}
              >
                Travel style
              </Typography>
              <Grid container direction="column" alignItems="start" spacing={2}>
                {travelStyles.map((style, index) => (
                  <Grid item key={index} xs={12}>
                    <button
                      className={`style-button ${
                        selectedStyle === style.name ? 'selected-style' : ''
                      }`}
                      onClick={() => handleSelection(style.name)}
                    >
                      <img
                        src={style.icon}
                        alt={style.name}
                        style={{ marginRight: '10px' }}
                        height="40px"
                      />
                      {style.name}
                    </button>
                  </Grid>
                ))}
                <Grid item>
                  <Box sx={{ width: '100%' }}>
                    <TextField
                      multiline
                      rows={2}
                      variant="outlined"
                      value={extraStyle}
                      onChange={(e) => setExtraStyle(e.target.value)}
                      placeholder="Add your extra requests. Separate each entry with a comma. This may impact the result."
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '4px',
                          backgroundColor: 'white',
                        },
                        width: '340px',
                      }}
                      InputProps={{
                        style: {
                          fontSize: '14px',
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className="progress-indicator-section">
          <Typography
            variant="h5"
            align="center"
            style={{ marginBottom: '20px', fontWeight: 'bold' }}
          >
            Visualize your holiday Step by Step
          </Typography>

          <ProgressIndicator steps={8} currentStep={8} />
          <div style={{ marginTop: '20px' }}>
            <NextButton onClick={handleNext} disabled={!selectedStyle} />
          </div>
          <div>
            {selectedStyle ? (
              <p></p>
            ) : (
              <p style={{ color: 'red' }}>Please select to proceed</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelStyleSelection;
