import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Chip,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ProgressIndicator from './ProgressIndicator';
import NextButton from './NextButton';
import { useQuery } from 'react-query';
import '../styles/CountrySelection.css';
import LoadingScreen from './loader';
import axios from 'axios';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ImageModal from './cityModal';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import NotFoundScreen from './NotFound';
import MapModal from './MapLocation';
import Header from './Header';
const CountrySelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const country = queryParams.get('country');
  const land = queryParams.get('land');
  const fetchData = (land, country) => {
    const url = `${process.env.REACT_APP_API_URL}/${
      land === 'island' ? 'select_cities_island' : 'select_cities_mainland'
    }`;
    return axios
      .get(url, {
        params: {
          country: country,
          mainland_island: land === 'island' ? 'island' : 'mainland',
        },
      })
      .then((response) => response.data);
  };

  const { isLoading, error, data } = useQuery(
    ['landData', land, country], // Ensure the keys are unique for each combination
    () => fetchData(land, country),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [newItemsList, setNewItemsList] = useState([]); // list for new items
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [cards, setCards] = useState([]);
  const handleNext = () => {
    const combinedItems = [...selectedCountries, ...newItemsList];
    const item = encodeURIComponent(combinedItems.join(',  '));
    navigate(`/getaway-selection?country=${country}&land=${land}&city=${item}`);
  };

  const handleCountryClick = (country) => {
    setSelectedCountries((prevSelected) => {
      if (prevSelected.includes('anywhere')) {
        if (country !== 'anywhere') {
          setIsModalOpen(true);
        }
        return [country];
      }
      if (prevSelected.includes(country)) {
        return prevSelected.filter((c) => c !== country);
      } else {
        if (country === 'anywhere') {
          setCards([]);
          return ['anywhere'];
        }
        setIsModalOpen(true);
        return [...prevSelected, country];
      }
    });
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };
  const handleAddNewItem = () => {
    if (!newItemsList.includes(searchTerm)) {
      setNewItemsList([...newItemsList, searchTerm]);
    }
    setSearchTerm('');
  };

  const handleRemoveLocation = (location) => {
    setNewItemsList(newItemsList.filter((loc) => loc !== location));
  };
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [images, setImages] = useState();
  const handleOpen = (img) => {
    setImages(img);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const sliderRef = React.useRef(null);

  const handleCardClick = (name) => {
    const index = cards.findIndex((card) => card.name === name);
    if (index !== -1) {
      setCards((prevCards) =>
        prevCards.map((card, idx) => ({
          ...card,
          selected: idx === index,
        }))
      );
      console.log(index);
      setSelectedIndex(index);
      const selectedCard = cards[index];
      if (selectedCard) {
        console.log('Selected Card ID:', selectedCard.id);
      }
      sliderRef.current.slickGoTo(index);
    } else {
      console.log(`Card with name "${name}" not found`);
    }
  };
  const handlePinClick = (name) => {
    const index = cards.findIndex((card) => card.name === name);
    if (index !== -1) {
      setCards((prevCards) =>
        prevCards.map((card, idx) => ({
          ...card,
          selected: idx === index,
        }))
      );
    }
  };
  const addCard = (name, location) => {
    const newCard = {
      id: `location-${cards.length + 1}`,
      name: name,
      selected: true,
      location: location,
    };
    setCards((prevCards) =>
      prevCards.map((card) => ({ ...card, selected: false })).concat(newCard)
    );
    console.log('Cards', cards);
    setSelectedIndex(cards.length);
    setTimeout(() => {
      sliderRef?.current?.slickGoTo(cards.length);
    }, 0);
  };
  const handleRemoveCard = (name) => {
    const index = cards.findIndex((card) => card.name === name);
    if (index !== -1) {
      const updatedCards = cards.filter((card) => card.name !== name);
      setCards(updatedCards);
      console.log(
        `Card with name "${name}" removed.`,
        selectedIndex,
        updatedCards.length
      );
      if (selectedIndex > updatedCards.length) {
        const newSelectedIndex = updatedCards.length - 1;
        setSelectedIndex(newSelectedIndex);
        sliderRef?.current?.slickGoTo(newSelectedIndex);
      }
    } else {
      console.log(`Card with name "${name}" not found.`);
    }
  };

  if (isLoading)
    return (
      <LoadingScreen
        text={'Please wait about 10 sec, our AI is producing the locations...'}
        time={12}
      />
    );
  if (error) return 'An error has occurred: ' + error.message;
  if (data.places && Object.keys(data.places).length === 0) {
    return <NotFoundScreen name={country} land={land} />;
  }

  return (
    <>
      {' '}
      <Header show={true} setIsModalOpen={setIsModalOpen} />
      <div className="selection-screen">
        <div className="selection-content">
          <div className="country-selection-screen">
            <Typography
              variant="h5"
              gutterBottom
              style={{ fontWeight: 'bold' }}
              pt={1}
            >
              Select one or several {land === 'island' ? 'island' : 'cities'}
            </Typography>
            <Grid item xs={12} sm={6} md={4} pb={2}>
              <button
                className={`country-button ${
                  selectedCountries.includes('anywhere') ? 'selected' : ''
                }`}
                onClick={() => handleCountryClick('anywhere')}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                  Top picks for you
                </span>
                <img
                  src={
                    !selectedCountries.includes('anywhere')
                      ? 'https://res.cloudinary.com/dyidgwoxx/image/upload/v1733329647/bvlvfaiuazellcqh7fu5.png'
                      : 'https://res.cloudinary.com/dyidgwoxx/image/upload/v1733345297/vmnhvk5fuh31gzkpaxnp.svg'
                  }
                  alt="Anywhere Icon"
                  style={{
                    width: '16px',
                    height: '16px',
                    paddingBottom: '6px',
                    paddingRight: '1px',
                    objectFit: 'contain',
                  }}
                />
              </button>
            </Grid>
            <TextField
              label="Search a Location name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ backgroundColor: 'AppWorkspace', borderRadius: '6px' }}
              InputProps={{
                endAdornment: (
                  <>
                    {/* Display "Add" icon if search term is not in the list */}
                    {searchTerm && (
                      <InputAdornment
                        position="end"
                        sx={{ marginRight: '-8px' }}
                      >
                        <IconButton
                          aria-label="add location"
                          onClick={handleAddNewItem}
                          edge="end"
                        >
                          <AddIcon />
                        </IconButton>
                      </InputAdornment>
                    )}
                    <InputAdornment position="end">
                      {searchTerm && (
                        <IconButton
                          aria-label="clear search"
                          onClick={handleClearSearch}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  </>
                ),
              }}
            />
            <Stack direction="row" spacing={1}>
              {newItemsList.map((location) => (
                <Chip
                  key={location}
                  label={location}
                  onDelete={() => handleRemoveLocation(location)}
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: '14px',
                    color: 'black',
                    '& .MuiChip-deleteIcon': {
                      color: 'black',
                      fontSize: '18px',
                    },
                  }}
                />
              ))}
            </Stack>
            {data &&
              Object.entries(data?.places)?.map(([region, countryList]) => {
                const filteredCities = countryList.filter((city) =>
                  city?.placename
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase())
                );
                if (filteredCities.length > 0) {
                  return (
                    <div className="country-group">
                      <Typography variant="h6" className="region-title">
                        {region}
                      </Typography>
                      <Grid container spacing={2}>
                        {filteredCities?.map((country, index) => (
                          <Grid
                            xs={12}
                            sm={6}
                            md={6}
                            key={index}
                            container
                            pt={2.5}
                            pl={3}
                          >
                            <Grid
                              container
                              xs={12}
                              sm={12}
                              md={12}
                              style={{ display: 'flex', alignItems: 'stretch' }}
                            >
                              <Grid
                                xs={10}
                                sm={10}
                                md={10}
                                pr={0.4}
                                style={{
                                  display: 'flex',
                                  alignItems: 'stretch',
                                  height: '72px',
                                }}
                              >
                                <button
                                  className={`country-button ${
                                    selectedCountries.includes(
                                      country.placename
                                    )
                                      ? 'selected'
                                      : ''
                                  }`}
                                  onClick={() => {
                                    if (
                                      selectedCountries.includes(
                                        country.placename
                                      )
                                    ) {
                                      handleRemoveCard(country.placename);
                                    } else {
                                      console.log('HELLLO');
                                      addCard(country.placename, country);
                                    }
                                    handleCountryClick(country.placename);
                                  }}
                                  style={{
                                    borderRadius: '5px 0 0 5px',
                                    margin: 0,
                                    width: '100%',
                                    // display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                  }}
                                >
                                  <strong>{country.placename}:</strong>{' '}
                                  {country.description}
                                </button>
                              </Grid>
                              <Grid
                                xs={2}
                                sm={2}
                                md={2}
                                style={{
                                  display: 'flex',
                                  alignItems: 'stretch',
                                }}
                              >
                                <button
                                  className={`country-button`}
                                  onClick={() =>
                                    handleOpen(country?.images_url)
                                  }
                                  style={{
                                    padding: 0,
                                    border: 'none',
                                    background: 'none',
                                    width: '100%',
                                    height: '100%',
                                    overflow: 'hidden',
                                    borderRadius: '0 5px 5px 0',
                                    margin: 0,
                                    display: 'flex',
                                    alignItems: 'stretch',
                                  }}
                                >
                                  <img
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      // objectFit: 'cover',
                                    }}
                                    src={
                                      country.images_url[0] ||
                                      'https://res.cloudinary.com/dyidgwoxx/image/upload/f_auto,q_auto/aliulllt3mx2ihdiiqed'
                                    }
                                    alt={country.placename}
                                  />
                                </button>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  );
                }
                return null;
              })}
          </div>
          <div className="progress-indicator-section">
            <Typography
              variant="h5"
              align="center"
              style={{ marginBottom: '20px', fontWeight: 'bold' }}
            >
              Visualize your holiday Step by Step
            </Typography>

            <ProgressIndicator steps={8} currentStep={4} />
            <div style={{ marginTop: '20px' }}>
              <NextButton
                onClick={handleNext}
                disabled={!selectedCountries.length}
              />
            </div>
            <div>
              {selectedCountries.length ? (
                <p></p>
              ) : (
                <p style={{ color: 'red' }}>Please select to proceed</p>
              )}
            </div>
          </div>
        </div>
        <ImageModal open={open} onClose={handleClose} images={images} />
        {data && (
          <MapModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(!isModalOpen)}
            locations={data}
            handleCountryClick={handleCountryClick}
            selectedCountries={selectedCountries}
            newItemsList={newItemsList}
            handleRemoveLocation={handleRemoveLocation}
            handleCardClick={handleCardClick}
            handleRemoveCard={handleRemoveCard}
            addCard={addCard}
            cards={cards}
            sliderRef={sliderRef}
            handlePinClick={handlePinClick}
            selectedIndex={selectedIndex}
          />
        )}
      </div>
    </>
  );
};

export default CountrySelection;
